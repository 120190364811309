#pomodoro-clock {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #282c34;
  color: white;
  font-family: 'Roboto', sans-serif;
}

.length-controls {
  display: flex;
  justify-content: space-around;
  width: 100%;
  max-width: 500px;
  margin-bottom: 20px;
}

h3 {
  margin-bottom: 10px;
}

button {
  background-color: #61dafb;
  border: none;
  border-radius: 5px;
  color: #282c34;
  cursor: pointer;
  padding: 10px 15px;
  font-size: 1.2em;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #21a1f1;
}

#timer {
  text-align: center;
  margin-bottom: 20px;
}

#time-left {
  font-size: 3em;
  margin: 10px 0;
}

#controls {
  display: flex;
  justify-content: space-around;
  width: 100%;
  max-width: 300px;
}
